.page-logo {
    width: 90%;
    max-width: 600px;
    height: auto;
}

h2.ui.header.submit-preview {
    border-bottom: solid 1px #000;
}

.fade-enter, .fade-appear {
    opacity: 0.01;
    z-index: 100;
}

.fade-enter.fade-enter-active, .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 300ms ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit.fade-exit-active {
    opacity: 0.01;
    transition: opacity 200ms ease-in;
}

.ui.items>.item>.content>.header:not(.ui) {
    font-size: 2rem;
    line-height: 2rem;
}

.main-route-frame {
    position: relative;
}

.main-route-content {
    position: absolute;
    top: 25px;
    left: 0;
    width: 100%;
}

.event-calender {
    height: calc(100vh - 100px);
}